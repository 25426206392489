<script>
  import { updateOtherSaleProductItem, removeOtherSaleProductProduct } from '../../stores/pricing';
  import { _ } from 'svelte-i18n';
  import ConfirmableInlineInput from '../../components/ConfirmableInlineInput.svelte';
  import Select from '../../components/Select.svelte';

  const onTicketChanged = ticketChange => {
    const newValue = ticketChange.detail.value;
    if ( item[ticketChange.detail.id.field] === newValue ) {
      return;
    }

    if ( ticketChange.detail.id.field === 'fixedPrice' ) {
      item.fixedPrice = ticketChange.detail.value;
    } else {
      item.name = ticketChange.detail.value;
    }


    updateOtherSaleProductItem(companyId, priceTable, item);
  };

  const onVatChanged = () => {
    updateOtherSaleProductItem(companyId, priceTable, item);
  };
  export let priceTable;
  export let companyId;
  export let editingMode;
  export let item;
  export let index;
</script>

<style>
  td {
    border-bottom: solid 1px #D1D1D2;
    padding: 0.75rem 1rem;
  }
  td:last-child {
    text-align: right;
  }
  td.editable {
    padding: 0rem calc(0.6rem - 1px);
    background-color: #eef5fb;
  }
  td.editable.editable-select {
    padding: 0 calc(0.75rem - 0.4em);
  }
  .text-button {
    padding: 0.75rem 1rem;
    color: #001E61;
    cursor: pointer;
  }
  .text-button:hover {
    color: #173043;
  }
</style>

{#if editingMode}
  <td class="editable">
    <ConfirmableInlineInput
      inputId={{ id: item.ticketIdentifier, field: 'name' }}
      type="text"
      value={item.name}
      on:complete={onTicketChanged}
    />
  </td>
  <td class="editable">
    <ConfirmableInlineInput
      inputId={{ id: item.ticketIdentifier, field: 'fixedPrice' }}
      type="price"
      textAlignment="right"
      value={item.fixedPrice}
      on:complete={onTicketChanged}
    />
  </td>
  <td class="editable editable-select">
    <Select
      textAlignment="right"
      bind:value={item.vat}
      on:change={onVatChanged}
      entries={[
        { label: '0', value: 0 },
        { label: '10', value: 10 },
        { label: '14', value: 14 },
        { label: '25.5', value: 25.5 },
      ]}
    />
  </td>
  <td
    on:click={() => removeOtherSaleProductProduct(companyId, priceTable, index)}
    class="text-button remove"
  >
    {$_('pricing.remove')}
  </td>
{:else}
  <td>{item.name}</td>
  <td class="text-right">
    {(+item.fixedPrice).toLocaleString('fi-FI', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    })}
  </td>
  <td>{item.vat}</td>
{/if}
