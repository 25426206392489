export default {
  loading: 'Loading...',
  back: 'Back',
  header: {
    title: 'Matkassa Pilvi',
    login: 'Log in',
  },
  login: {
    title: 'Log in',
    action: 'Log in',
    forgotten: 'Password forgotten?',
    email: 'Email',
    password: 'Password',
    reset_instr:
      'Enter your email address. We will send you a confirmation code to reset your password.',
    reset: 'Reset password',
    change: 'Change password',
    change_instr:
      'Your password is temporary and has to be changed.\nEnter a new password below.',
    forgotten_instr:
      'You have received a confirmation code in your inbox. Enter the confirmation code as well as a new password below.',
    newPassword: 'New password',
    retypeNewPassword: 'Retype new password',
    alreadyConfirmed: 'I already have a confirmation code',
    confirmationCode: 'Confirmation code',
    passwordMatch: 'Passwords must match',
    invalidCredentials: 'Invalid credentials',
    error: 'An error happened, please try again.',
    userNotFound: 'User does not exist.',
    passwordChanged: 'Password changed successfully',
    sessionExpired: 'Session expired',
  },
  reports: {
    title: 'Check-out reports',
  },
  calendar: {
    days: {
      long: {
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tueday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
      },
      short: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
      shortSentenceCase: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
    },
    months: {
      long: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
      },
      short: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
  },
  users: {
    title: 'User management',
    users: 'Users',
    create: 'Create user',
    name: 'Name',
    username: 'Email / Username',
    company: 'Company',
    admin: 'Admin',
    edit: 'Edit',
    editor: {
      title: 'Edit user info',
      firstname: 'First name',
      lastname: 'Last name',
      email: 'Email address',
      password: 'Password',
      seeCompanies: 'User can see following companies',
      done: 'Done',
      cancel: 'Cancel',
      create: 'Create user',
      delete: 'Delete user',
      unresettable:
        'If the user cannot reset their password, delete them and recreate them.',
      deleteSure: 'Are you sure you want to delete the user?',
      deleteSub: 'User: {1}, {2}',
      admin: 'The user has admin rights in the user management for the chosen companies',
      adminInfo:
        'If you give the user admin privileges, they can add and edit users in the chosen companies.',
      features: 'The new user sees the following features for the chosen companies',
      userCreated: 'User created',
      userUpdated: 'User updated',
      userDeleted: 'User deleted',
      minimumLength: 'The password needs to contain at least {1} characters.',
      requireUppercase: 'The password requires at least one uppercase.',
      requireLowercase: 'The password requires at least one lowercase.',
      requireNumbers: 'The password requires at least one number.',
      requireSymbols: 'The password requires at least one symbol.',
      allLines: 'Access to all departures',
      selectedLines: 'Access to following departures',
      lines: {
        line: 'Line',
        departure: 'Departure',
        remove: 'Remove',
        add: 'Add line',
        invalid: 'At least one line is needed',
      },
    },
  },
  lines: {
    title: 'Line management',
    sections: {
      charter: 'Charter',
      serviceLines: 'Service lines',
      lineSeries: 'Line services',
      rlTickets: 'RL tickets',
      kilometers: 'Kilometers',
      waltti: 'Waltti-departures',
    },
  },
  map: {
    title: 'Map',
    search: 'Search with departure or line',
    filter: 'Filter search',
    details: 'See details',
    showOtherLines: 'Show other lines',
    onRoad: 'Buses on the road',
    late: 'Late',
    travelers: 'Travelers',
    shipments: 'Shipments',
    basic: 'Basic',
  },
  travelReports: {
    title: 'Travel reports',
  },
  error: {
    title: 'Error',
  },
  search: {
    define: 'Define search terms',
    fetch: 'Search',
    start: 'Start',
    end: 'End',
    departureId: 'Departure ID',
    lineId: 'Line ID',
    driverId: 'Driver ID',
    deviceId: 'Device ID',
    receiptNumber: 'Receipt number',
    company: 'Company',
    choose: {
      date: 'Choose a date',
      departureId: 'Write departure ID',
      lineId: 'Write line ID',
      driverId: 'Write driver ID',
      deviceId: 'Choose device',
      receiptNumber: 'Write receipt number',
      weekdayFilter: 'Filter weekdays',
      weekdays: {
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Sunday: 'Sunday',
      },
    },
    showOnlyUnaccounted: 'Show only unaccounted',
    showOnlyAdded: 'Show only added rows',
  },
  results: {
    noResults: 'No results',
    date: 'Date',
    departure: 'Departure',
    line: 'Line',
    driver: 'Driver',
    device: 'Device',
    company: 'Company',
    productName: 'Product name',
    productNames: {
      TICKET_SALE: 'One-time ticket',
      CASH_PURCHASE: 'One-time ticket',
      NO_SALE: 'Other sale',
      ONLINE_TICKET: 'Prepurchase',
      TRAVEL_CARD_SERIES: 'Series card',
      TRAVEL_CARD_SEASON: 'Seasonal card',
      TRAVEL_CARD_VALUE: 'Value card',
      ROUNDING: 'Rounding',
      ADDITIONAL_SERVICE: 'Additional service',
      BIKE_TICKET: 'Bike ticket',
      MATKASSA_PRO_CONNECTION: 'Connecting ticket',
      SEAT_RESERVATION: 'Seat reservation',
      RL_TICKET: 'RL Mobile Ticket',
    },
    receiptNumber: 'Receipt number',
    paymentMethod: 'Payment method',
    vat: 'VAT %',
    vatFreePrice: 'VAT-free price',
    priceVAT: 'Price (incl. VAT)',
    totalCount: 'Total count %d lines',
    totalSum: 'Total',
    cashSum: 'Cash transactions ({1} pcs)',
    cardSum: 'Card transactions ({1} pcs)',
    oneTimeSum: 'One-time tickets ({1} pcs)',
    travelCardSum: 'Travel card ({1} pcs)',
    otherSale: 'Other sale ({1} pcs)',
    cancelled: 'Cancelled ({1} pcs)',
    accountedSum: 'Accounted ({1} pcs)',
    unaccountedSum: 'Unaccounted ({1} pcs)',
    createExcel: 'Create Excel',
    report: 'Report',
    trip: 'Trip',
    markAccounted: 'Mark accounted',
    unmarkAccounted: 'Unmark accounted',
    cancelEvent: 'Cancel event',
    cancelEventConfirmationTitle: 'Event cancellation',
    cancelEventConfirmationText: 'Do you really want to cancel this event?',
    cancelledSale: 'Cancelled',
    showOnlySelected: 'Show summary for selected rows',
    goBack: 'Go back',
    addRow: 'Add row',
    editRow: 'Edit row',
    editAddedRow: 'Edit added row',
    addRowFailed: "Couldn't add row",
    editRowFailed: "Couldn't edit row",
    add: 'Add',
    explanation: 'Explanation',
    explanationLong: 'Text is shown on report.',
    sum: 'Sum',
    sumLong: 'Positive or negative.',
    createAccountingReport: 'Download accounting report',
    createAccountedReport: 'Download report of accounted',
    receiptNotArrived: 'Receipt has not been saved to Pilvi yet.',
  },
  travel: {
    line_length: 'Distance driven',
    daily: 'Daily summary',
    stop: 'Stop-based summary',
    dayDate: 'Date',
    eventHour: 'Timestamp',
    nextDayEvent: '* next day event',
    origin: 'Origin',
    destination: 'Destination',
    distance: 'Travel distance (km)',
    customerGroup: 'Customer group',
    price: 'Price € VAT 0%',
    prepurchase: 'Prepurchase pcs',
    prepurchaseSold: 'Prepurchase sold pcs',
    prepurchaseValidated: 'Prepurchase validated pcs',
    prepurchaseSum: 'Prepurchase sold €',
    cash: 'Cash pcs',
    cashSum: 'Cash €',
    travelcard: 'Tickets with ID pcs',
    travelcardSum: 'Tickets with ID €',
    noSale: 'Registered without sale pcs',
    noSaleSum: 'Registered without sale €',
    total: 'Total pcs',
    totalSum: 'Total €',
    totalSumVatFree: 'Total € (VAT 0%)',
    vatFreeSummary: 'Total (VAT 0%)',
    travelerAvg: 'Traveler avg.',
    stopName: 'Stop',
    ticketProduct: 'Ticket type',
    max: 'Travelers max.',
    boarding: 'Boarding avg.',
    leaving: 'Leaving avg.',
    allTravelEvents: 'All travel events',
    services: {
      BIKE_TICKET: 'Bike ticket',
    },
    euroPerKm: '€/km',
    byLineMax: 'max.',
    avg: 'avg.',
    totalLinesPerDay: '%d lines in total',
    totalLinesPerDayPerTotal: '{totalCount}/{maxShifts} lines in total',
    reportByLine: 'All from selected period',
    reportGroupedByLine: 'Line summary',
    reportGroupedByNoSales: 'No sale summary',
    reportByDay: 'Grouped by day',
    searchGroupedLines: 'Fetch line summary in own tab',
    searchGroupedNoSales: 'Fetch no sale summary in own tab',
    all: 'All',
    createExcel: 'Export to Excel:',
    exportResults: 'search results',
    exportEvents: 'search result event rows',
    exportProducts: 'search result product listing',
    productId: 'Product ID',
  },
  pricingMaintenance: {
    title: 'Pricing and lines',
  },
  payment: {
    methods: {
      CASH: 'Cash',
      CARD: 'Card',
      ONE_TIME_PAYMENT: 'One-time',
      TRAVELCARD: 'Travel card',
    },
  },
  pricing: {
    zonePricing: 'Zone pricing',
    customerGroup: 'Customer group',
    zone: 'Zone',
    sections: {
      basic: 'Basic pricing',
      special: 'Special pricings',
      other: 'Other sales',
      noSale: 'Registration without sale',
      zone: 'Zone tickets',
      lineSeries: 'Line series',
    },
    modify: 'Edit',
    done: 'Done',
    addNewProduct: 'Add',
    add: 'Add',
    addPricing: 'Add',
    addRow: 'Add',
    remove: 'Remove',
    setAsDefault: 'Set as default',
    name: 'Name',
    text: 'Text',
    priceShort: '€',
    price: 'Price',
    vat: 'VAT %',
    otherTicketProducts: 'Other tickets, VAT 14%',
    otherProducts: 'Other products, VAT 0/10/14/25.5%',
    noProducts: 'No products',
    travelRightDescription: 'Eligibility descriptions',
    ready: 'Done',
    zoneName: 'Zone pricing name:',
    basicPricingDefault: 'Basic prices',
    basicPricingDefaultTag: '(default)',
    basicPricingMissing: 'Basic pricing does not yet exist.',
    specialPricesMissing: 'Special pricings do not yet exist.',
    otherTicketsMissing: 'Other tickets do not yet exist.',
    otherProductsMissing: 'Other products do not yet exist.',
    travelRightDescriptionsMissing: 'Eligibility descriptions do not yet exist',
    zoneTicketsMissing: 'Zone prices do not yet exist',
    addCustomerGroup: 'Add customer group',
    modifyCustomerGroup: 'Edit customer group',
    removeCustomerGroup: 'Remove customer group',
    departuresMissing: 'Departures have not been specified',
    addDistance: 'Add distance',
    addOtherProduct: 'Add other product',
    addOtherTicket: 'Add other ticket',
    addTravelRightDescription: 'Add passenger eligibility description',
    addZoneTicket: 'Add new zone pricing',
    addNewBasicPricing: 'Add new basic pricing',
    addNewSpecialPriceTable: 'Add new special pricing',
    removeBasicPriceTable: 'Remove basic pricing',
    removeBasicPriceTableConfirmation: 'Do you really want to remove the basic pricing?',
    removeSpecialPricing: 'Remove special pricing',
    removeSpecialPricingConfirmation:
      'Do you really want to remove this special pricing?',
    removeZoneTickets: 'Remove zone pricing',
    removeZoneTicketsConfirmation: 'Do you really want to remove the zone pricng?',
    basicPricingCreationTitle:
      'Do you want to copy an already existing pricing or create a new one?',
    basicPricingCopySelectCompany:
      'Choose from which company the price table will be copied: *',
    basicPricingCopySelectPriceTable: 'Choose price table: *',
    basicPricingFromScratch: 'Do not copy, create a price table from scratch',
    basicPricingFromDefault: 'Copy the default basic pricing',
    basicPricingFromExisting: 'Copy price table from existing table',
    modifyBasicPricing: 'Edit basic pricing',
    modifyZoneTickets: 'Edit zone pricing',
    distance: 'Distance',
    distanceShort: 'km',
    continue: 'Continue',
    cancel: 'Cancel',
    discount: 'Discount',
    yes: 'Yes',
    no: 'No',
    errorDistanceAlreadyExists: 'Distance already exists',
    errorZoneAlreadyExists: 'Zone already exists',
    errorLineAlreadyExists: 'Departure has already been specified',
    errorPriceTableNameAlreadyExists: 'A price table with the same name already exists',
    appliesToLines: 'Departures:',
    errorCannotDeleteLastZone: 'Cannot remove last zone',
    errorCannotDeleteLastDeparture: 'Cannot remove last departure',
    chooseFileForSpecialPricing: 'Choose file for special pricing',
    chooseOtherTableForSpecialPricing: 'Or choose another table to use as a a base:',
    chooseFile: 'Browse',
    modifySpecialPricing: 'Modify special pricing',
    loadNewFile: 'Upload new file',
    updatePricingWithFile: 'Update pricing with new file',
    updatePricingNotification: 'Note that the existing pricing will be overwritten!',
    specialPricingType: 'Pricing type',
    specialDistancePricingTable: 'Distance-based fee table',
    specialConnectionPricingTable: 'Price-based fee table',
    errorFileReadingFailed: 'Failed to read file',
    errorFileDataNotValid: 'File content is invalid',
    validityTimeMinutes: 'Validity (min)',
    pricingAsDefaultConfirmationText:
      'You are about to set the current price table as default for all lines without a separately specified price table.\n\nDo you wish to continue?',
    errorPricingModified:
      'Prices have been modified on the server; prices will be refetched',
  },
  lineSeries: {
    title: 'Line series',
    noLineSeriesExist: 'Line series have not yet been created for this company.',
    addLineSeries: 'Add line series',
    createNewLineSeries: 'Create new line series',
    modifyLineSeries: 'Modify line series',
    lineSeriesName: 'Line series name:',
    lineSeriesOrder: 'Series order:',
    validity: 'Validity:',
    line: 'Line',
    departure: 'Departure',
    removeLineSeries: 'Delete line series',
    removeLineSeriesConfirmation:
      'Are you sure you want to delete the current line series?',
    atleastOneWeekdayRequired: 'You must select at least one weekday',
    atleastOneLineCombinationRequired:
      'You must specify at least one line/departure combination',
  },
  kilometers: {
    title: 'Kilometers',
    linesAndDepartures: 'Lines and departures',
    modifyKilometers: 'Kilometers',
    line: 'Line',
    departure: 'Departure',
    atleastOneLineCombinationRequired: 'At least one line/departure pair must be given.',
    typeDescNone: 'Kilometers are not asked on any line/departure.',
    typeDescAll: 'Kilometers will be asked on all lines and departures.',
    typeDescSpecific:
      'Kilometers will be asked on combinations of lines and departures indicated by the table below.',
    typeSelectNone: 'will not be asked on any line',
    typeSelectAll: 'will be asked on all lines and departures',
    typeSelectSpecific: 'will be asked only on the following lines and departurus',
    noLinesDefined: 'No lines or departures have been entered.',
  },
  waltti: {
    modify: 'Waltti-lines',
    name: 'Waltti-name',
    title: 'Waltti-departures',
    linesAndDepartures: 'Lines and departures',
    desc: 'Here you can define Minfo-departures on which Waltti-tickets are accepted while operating in Waltti-areas.',
    desc2:
      'Please note that it is mandatory for a company to have Waltti-connection done before Matkassa-devices will accept Waltti products.',
    line: 'Line',
    departure: 'Departure',
    atleastOneLineCombinationRequired: 'At least one line/departure pair must be given.',
    typeDescAll: 'Waltti-tickets will be accepted on all departures.',
    typeDescSpecific:
      'Waltti-tickets will be accepted on the following lines and departures.',
    typeSelectAll: 'will be accepted on all lines and departures',
    typeSelectSpecific: 'will be accepted only on the following lines and departures',
    noLinesDefined: 'No lines or departures have been entered.',
    noRights: 'No Waltti-rights.',
  },
  messages: {
    title: 'Messages',
    create: 'Create new message',
    categories: {
      title: 'Category',
      INSTRUCTION: 'Instruction',
      GOOD_TO_KNOW: 'Good to know',
      IMPORTANT: 'Important',
      DISTURBANCE: 'Disturbance',
    },
    choose: 'Choose',
    start: 'Activation date',
    end: 'Deactivation date',
    target: {
      name: 'Target',
      all: 'All',
      selectedLines: 'Selected departures',
    },
    allLines: 'Applies to all departures',
    selectedLines: 'Applies to following departures:',
    companies: 'Companies',
    line: 'Line',
    departure: 'Departure',
    addImage: 'Add image',
    chooseFile: 'Choose file',
    messageTitle: 'Message title',
    messageContent: 'Message content',
    writeTitle: 'Write title',
    writeContent: 'Write message content',
    publishMessage: 'Publish message',
    emptyFields: 'Empty fields',
    published: 'Published',
    titleColumn: 'Title',
    until: 'Until',
    messageInfo: 'Message information',
    editMessage: 'Edit message',
    edit: 'Edit',
    delete: 'Delete message',
    deleteSure: 'Are you sure you want to delete the message?',
    back: 'Back',
    save: 'Save',
    cancel: 'Cancel',
    fieldRequired: 'Field required',
    imageError: 'Image upload failed',
    messagePublished: 'Message published',
  },
  supportRequests: {
    title: 'Support requests',
    timestamp: 'Timestamp',
    deviceId: 'Device ID',
    device: 'Device',
    category: 'Category',
    status: {
      title: 'Status',
      HANDLED: 'Handled',
      NEW: 'New',
      IN_PROGRESS: 'In progress',
    },
    sendCommand: 'Send command',
    writeDeviceId: 'Write device ID',
    confirmExecuteTitle: 'Haluatko varmasti suorittaa komennon?',
    confirmExecuteBody: 'Target: {1}  \n  Command: {2}',
    confirmExecuteParams: 'Parameters: {1}',
    commands: {
      REMOTE_FORCE_LOGOUT_USER_COMMAND: 'Log user out',
      REMOTE_RESET_DEVICE_COMMAND: 'Reset device',
      REMOTE_SEND_DEVICE_INFO_COMMAND: 'Update device info',
      REMOTE_RESET_AND_DEACTIVATE_DEVICE_COMMAND: 'Reset and deactivate device',
      DELETE_UPDATE_LINE_COMMANDS: `Delete all "UPDATE_LINE" commands `,
      REMOTE_LIST_COMMANDS_COMMAND: 'List all commands',
      REMOTE_DUMP_DEBUG_SETTINGS: 'Get settings',
      DELETE_COMMAND_BY_ID_COMMAND: 'Delete selected commands ({1})',
      DELETE_COMMANDS_BY_TYPE_COMMAND: 'Delete by type ({1})',
      param_not_available: 'No commands selected',
    },
    execute: 'Execute',
    driver: 'Driver',
    company: 'Company',
    writeNote: 'Write a note here if you want',
    saveNote: 'Save note',
    writeComment:
      'Write a comment here if you want. Keep in mind that once marked as handled, a support request is closed. This means you will not be able to send commands to be executed.',
    markHandled: 'Mark as handled',
    updateInfo: 'Update device info',
    waitingForResponse: 'Waiting for response',
    actionType: {
      NOTE: 'Note',
      RESPONSE: 'Response',
      HANDLED: 'Handled',
    },
    reasons: {
      DEVICE_BLOCKED: 'Device blocked',
      OTHER: 'Other',
      SUPPORT_INITED: 'Support request initiated by support',
    },
    commandTable: {
      commandAmount: 'Commands in total: {1}',
      shortenText: 'Shorten text',
      '!shortenText': 'Do not shorten text',
      showDefaultAmount: 'Show first 10',
      showMore: 'show 50 more',
      showAll: 'Show all ({1})',
      hideCommands: 'Hide commands',
      showCommands: 'Show commands',
    },
    deviceNotFound: 'Device with specified ID not found',
  },
  rlTickets: {
    add: 'Add line',
    remove: 'Remove',
    line: 'Line ID',
    departure: 'Departure ID',
  },
  receipts: {
    title: 'Thank you, for traveling with us',
    intro: 'The receipt is available to download.',
    download: 'Download receipt (pdf)',
    error: 'Download failed',
    expired:
      'The receipt cannot be downloaded anymore.\n\nReceipts can be downloaded up to 6 months after purchase.',
    notYetAvailable:
      'The receipt has not been sent yet by the car it was bought in. Please wait a moment.',
    serviceOffered: 'Service offered by: {company}',
  },
};
