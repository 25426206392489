<script>
  import { _ } from 'svelte-i18n';
  import { getReceiptDownload } from '../../common/http';
  import { onMount } from 'svelte';
  import LoadingSpinner from '../../components/icons/LoadingSpinner.svelte';

  const getLastPathSegment = () => {
    const pathSegments = window.location.pathname.split('/').filter(Boolean); // Split and remove empty segments
    return pathSegments[pathSegments.length - 1] || ''; // Get the last element or empty string if no path
  };

  let error = null;
  let status = null;
  let url = null;
  let company = null;

  const handleRes = res => {
    status = res.status;
    url = res.data.url;
    company = res.data.company;
  };

  onMount(async () => {
    try {
      const id = getLastPathSegment();
      const res = await getReceiptDownload(id);
      handleRes(res);
    } catch (e) {
      if ([410, 404].includes(e.status)) {
        handleRes(e);
        return;
      }
      error = e;
    }
  });
</script>

<style>
  .error {
    color: #d0021b;
    font-size: 0.9rem;
  }
</style>

<main class="p-8">
  <h1 class="font-semibold">{$_('receipts.title')}</h1>
  <p class="mt-4">
    {#if status === 200}
      {$_('receipts.intro')}
    {:else if status === 404}
      {$_('receipts.notYetAvailable')}
    {:else if status === 410}
      {$_('receipts.expired')}
    {:else}
      <LoadingSpinner />
    {/if}

    {#if company}{' '}
      {$_('receipts.serviceOffered').replace('{company}', company)}
    {/if}
  </p>
  {#if url}
    <a href={url} target="_blank" class="button mt-4 w-fit no-underline"
      >{$_('receipts.download')}</a
    >{/if}
  {#if error}
    <p class="error mt-2">{error}</p>
  {/if}
</main>
