<script>
  import PricingZoneList from './PricingZoneList.svelte';
  import PricingZoneNew from './PricingZoneNew.svelte';

  let active = 'list';
  let currentZone;
  let zoneName;
  export let currentCompany;
</script>

{#if active === 'list'}
  <PricingZoneList bind:activeView={active} bind:zoneName={zoneName} bind:currentZone {currentCompany} />
{:else}
  <PricingZoneNew bind:activeView={active} bind:zoneName={zoneName} bind:currentZone {currentCompany} />
{/if}
