<script>
  import { onDestroy, onMount } from 'svelte';

  import Messages from './pages/Messages.svelte';

  import { _ } from 'svelte-i18n';
  import { currentUser } from './stores/user';
  import { currentTab } from './stores/tabs';

  import Header from './components/Header.svelte';
  import Notifications from './components/Notifications.svelte';
  import SpinnerOverlay from './components/SpinnerOverlay.svelte';

  import CheckoutReports from './pages/CheckoutReports.svelte';
  import PricingMain from './pages/pricing/PricingMain.svelte';
  import LinesMain from './pages/lines/LinesMain.svelte';
  import TravelReports from './pages/TravelReports.svelte';
  import Login from './pages/Login.svelte';
  import Map from './pages/Map.svelte';
  import UserManagement from './pages/UserManagement.svelte';
  import DeviceManagementMain from './pages/devices/DeviceManagementMain.svelte';
  import SupportRequests from './pages/SupportRequests.svelte';
  import Modal from 'svelte-simple-modal';

  import { spinnerState } from './stores/spinner.js';
  import { hasAnyFeature } from './stores/features';
  import Receipt from './pages/receipts/Receipt.svelte';

  $: features = ($currentUser && $currentUser.features) || [];

  let currentPath = window.location.pathname;

  const updatePath = () => {
    currentPath = window.location.pathname;
  };

  onMount(() => {
    window.addEventListener('popstate', updatePath);
    window.addEventListener('hashchange', updatePath);
  });

  onDestroy(() => {
    window.removeEventListener('popstate', updatePath);
    window.removeEventListener('hashchange', updatePath);
  });
</script>

<Modal closeButton={false}>
  <Notifications />
  <Header />
  <main class="flex flex-col items-start">
    {#if currentPath.startsWith('/receipts/')}
      <Receipt />
    {:else if $currentUser && $currentUser.loggedIn}
      {#if $currentTab === 'reports' && hasAnyFeature(features, 'REPORTS')}
        <CheckoutReports />
      {:else if $currentTab === 'travel-reports' && hasAnyFeature(features, 'TRAVELREPORTS')}
        <TravelReports />
      {:else if $currentTab === 'pricing-maintenance' && hasAnyFeature(features, 'PRICING')}
        <PricingMain />
      {:else if $currentTab === 'lines' && hasAnyFeature(features, 'LINES')}
        <LinesMain />
      {:else if $currentTab === 'map' && hasAnyFeature(features, 'MAP')}
        <Map />
      {:else if $currentTab === 'users' && hasAnyFeature(features, 'ADMIN')}
        <UserManagement />
      {:else if $currentTab === 'support' && hasAnyFeature(features, 'SUPPORT')}
        <SupportRequests />
      {:else if $currentTab === 'messages' && hasAnyFeature(features, 'MESSAGING')}
        <Messages />
      {:else if $currentTab === 'devices' && hasAnyFeature(features, 'DEVICES')}
        <DeviceManagementMain />
      {/if}
    {:else}
      <Login />
    {/if}
  </main>
  <SpinnerOverlay active={$spinnerState > 0} />
</Modal>
